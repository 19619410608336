import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import OKRs from '../views/OKRs.vue'
import OKRs_Archive from '@/views/OKRs_Archive.vue'
// import Product from '../views/Product.vue'
import Search from '../views/Unused_Search.vue'
import Teams from '../views/Teams.vue'
import Licenses from '@/views/Licenses.vue'
import SignUp from '../views/Unused_SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
// import Overview from '../views/Overview.vue'
import Unused_FrontPage from '@/views/Unused_FrontPage.vue'
import TestDataAnnotationB from '@/views/TestDataAnnotationWrapper_ResponseB.vue'
import TestDataAnnotationA from '@/views/TestDataAnnotationWrapper_ResponseA.vue'
import Changelog from '@/views/Changelog.vue'
import PrivacyStatement from '@/views/PrivacyStatement.vue'
import TermsAndConditions from '@/views/Unused_TermsAndConditions.vue'
import Imprint from '@/views/Imprint.vue'
import Company from '@/views/Company_Overview.vue'
import PasswordResetConfirm from '@/views/PasswordResetConfirm.vue'
import Blank from '@/views/Blank.vue'





const routes = [
  // {
  //   path: '/overview',
  //   name: 'Overview',
  //   component: Overview,
  //   meta: {
  //     requireLogin: true
  //   }
  // },
  {
    path: '/okrs',
    name: 'OKRs',
    component: OKRs,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/okrs-archive',
    name: 'OKRs_Archive',
    component: OKRs_Archive,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/sign-up-to-okr',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in-to-okr',
    name: 'LogIn',
    component: LogIn
  },
  {
    // path: '/password-reset-confirm/:reset_link', // Also an option?
    path: '/password-reset-confirm/:uidb64/:token',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    // meta: {
    //   requireLogin: false
    // }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/teams',
    name: 'Teams',
    component: Teams,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: Licenses,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
    meta: {
      requireLogin: true,
      requirePermissions: ['view_changelog']
    }
  },
  // {
  //   path: '/front-page',
  //   name: 'Front-Page',
  //   component: FrontPage,
  //   meta: {
  //     requireLogin: false
  //   }
  // },
  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: PrivacyStatement,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: {
      requireLogin: false
    },
  },
  {
    path: '/blank',
    name: 'Blank',
    component: Blank,
    meta: {
      requireLogin: false
    }
  },
  // {
  //   path: '/test-data-annotation-b',
  //   name: 'TestDataAnnotationB',
  //   component: TestDataAnnotationB,
  //   meta: {
  //     requireLogin: false
  //   }
  // },
  // {
  //   path: '/test-data-annotation-a',
  //   name: 'TestDataAnnotationA',
  //   component: TestDataAnnotationA,
  //   meta: {
  //     requireLogin: false
  //   }
  // },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin)  && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path} });
  // } else if (to.matched.some(record => record.meta.requirePermissions) && !store.state.permissions.includes(to.meta.requirePermissions)) {
  //   next({ name: 'OKRs' });
  } else {
    next()
  }
})

export default router
