// filepath: /D:/1_Luka/Documents/Code/Djackets_Luka/djackets_vue/src/utils/logger.js
const isDevelopment = process.env.NODE_ENV === 'development';

// if (!isDevelopment) {
//     console.log = function () { };
//     console.error = function () { };
//     console.warn = function () { };
//     // Override other console methods if needed
// }

export default {};