import axios from 'axios';

export async function getPermissions() {
  // console.log('inside get permissions util function');
  try {
    const response = await axios.get('/api/v1/me/permissions');
    console.log('permissions util function:', response.data.permissions);
    return response.data.permissions;
  } catch (error) {
    console.error('Error fetching permissions:', error);
    throw error;  // Optionally re-throw the error if you want to handle it further up the call stack
  }
}

export function progressBarBackgroundColor(objectiveProgress, threshold_green, threshold_red) {
  return 'bg-gray-200';
  if (objectiveProgress < threshold_red / 100) {
      return '#8B0000';
    // } else if (objectiveProgress < threshold_yellow / 100) {
    //   return '#cc8400';
    } else if (objectiveProgress < threshold_green / 100) {
      return '#cccc00';
    } else {
      return '#006600';
    }
}

export function progressBarColor(progress, threshold_green, threshold_red) {
  // console.log("progress:", progress, "threshold_green:", threshold_green, "threshold_red:", threshold_red);
  // return 'bg-gray-500';

  if (progress == null) 
    return 'bg-white';
  if (progress < threshold_red) {
      return 'bg-red';
  // } else if (objectiveProgress < threshold_yellow/100) {
  //     return 'orange';
  } else if (progress < threshold_green) {
      return 'bg-yellow';
  } else {
    return 'bg-green';
  }
}

export function calculateKeyresultProgress(keyresult) {
  // console.log("keyresult okok:", keyresult);
  // Ensure goal, start, and value_current are numbers for calculation
  const goal = parseFloat(keyresult.goal);
  const startValue = parseFloat(keyresult.start);
  const currentValue = parseFloat(keyresult.value_current);
  // console.log("id:", keyresult.id)
  const index = keyresult.id;

  // Check if objective and working_period exist
  if (!keyresult.objective) {
    console.error(`Objective or working period is missing for keyresult at index ${index}`);
    return;
  }

  const workingPeriod = keyresult.objective.working_period;

  // Check if start and end times exist
  if (!keyresult.working_period_start || !keyresult.working_period_end) {
    console.error(`Start or end time is missing for working period at index ${index}`);
    return;
  }

  // Get the start and end times from the working period
  const startTime = new Date(keyresult.working_period_start);
  const endTime = new Date(keyresult.working_period_end);

  // Mocking Data
  // const mocking_testTime = new Date();
  // mocking_testTime.setMonth(mocking_testTime.getMonth() + 0);

  const totalTimeSpan = endTime - startTime;
  const elapsedTimeSpan = Date.now() - startTime.getTime();

  // Mocking Data
  // const elapsedTimeSpan = mocking_testTime - startTime.getTime();

  const expectedProgressRatio = totalTimeSpan > 0 ? elapsedTimeSpan / totalTimeSpan : 0;
  const expectedValue = startValue + (goal - startValue) * expectedProgressRatio;

  let actualProgress = 0;
  if (goal - startValue !== 0) {
    actualProgress = ((currentValue - startValue) / (goal - startValue)) * 100;
  }

  const expectedProgress = ((expectedValue - startValue) / (goal - startValue)) * 100;

  let progress = 0;
  if (expectedProgress !== 0) {
    keyresult.progress = actualProgress / expectedProgress * 100;
  }

  return {
    ...keyresult,
    // progress,
    // actual_progress: actualProgress,
    // expected_progress: expectedProgress
  };
}

export function calculateObjectivePercentage(keyresults) {
    let percentage = 0;
    let totalPercentage = 0;
    let totalKeyresults = 0;
    keyresults.forEach(keyresult => {
        totalPercentage += keyresult.progress_percentage;
        totalKeyresults += 1;
    });
    if (totalKeyresults > 0) {
        percentage = totalPercentage / totalKeyresults;
    }
    return percentage;
}

export function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2); // Add leading zero if needed
  const day = (`0${today.getDate()}`).slice(-2); // Add leading zero if needed
  // Set time to "00:00" to comply with the "datetime-local" input format
  const time = 'T00:00';
  return `${year}-${month}-${day}${time}`;
}

