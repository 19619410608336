<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Neues Moal hinzufügen
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <input type="text" class="input-field-big" placeholder="Neues Moal" v-model="name">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Mission:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="mission">
                            <option v-for="mission in missions" :value="mission.id">{{ mission.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Gewicht:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" placeholder=" " v-model.number="weight_multiplier">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_AddMoal',
    emits: ['moalAdded'],
    props: {
        missions: Array,
    },
    data() {
        return {
            name: '',
            mission: '',
            weight_multiplier: 1,
            errors: [],
            showHelpText: false,
        }
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.name === '') {
                this.errors.push('The name field is missing!')
            }
            if (this.mission === '') {
                this.errors.push('The mission field is missing!')
            }
            if (!this.errors.length) {
                this.addMoal();
            }
        },
        async addMoal() {
            this.$store.commit('setIsLoading', true)
            console.log('Adding new Moal:', this.name, this.mission)
            await axios
                .post('/api/v1/moals/', 
                {
                name: this.name,
                mission: this.mission,
                weight_multiplier: this.weight_multiplier,
                },
                )
                .then(response => {
                // console.log('Response:', response.data);
                this.$emit('moalAdded');
                })
                .catch(error => {
                    if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
