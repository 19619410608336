<template>
    <!-- <tr class="has-background-info-light"> -->
        <!-- ##################################### Name ################################## -->
        <td class="vertical-center-table table-cell text-left">
                    <strong>{{ team.name }}</strong>
        </td>

        <!-- ##################################### team lead ################################## -->
        <td  class="vertical-center-table table-cell text-center">
            <!-- TODO change to without if -->
            <div v-if="team && team.teamlead">{{ team.teamlead_name }}</div> 
        </td>

        <!-- ##################################### Team members ################################## -->
         <!-- TODO make them appear from left to right with wrapping when reaching the end of a cell -->
        <td class="vertical-center-table text-center">
            <div v-for="member in team.member_names">{{ member }}</div>
        </td> 

        <!-- ##################################### Checkin day ################################## -->
        <td class="vertical-center-table table-cell text-center">
            <div>{{ team.checkin_day_display }}</div>
        </td>

        <!-- ##################################### Actions ################################## -->
        <td class="align-middle vertical-center-table">
            <table class="w-full border-collapse">
                <tr>
                    <td class="text-center" v-if="permissions.includes(`olp_change_team_${team.id}`)">
                        <button class="actionButton" @click="isChangeTeamModalOpen = true" >Ändern</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isChangeTeamModalOpen" class="modal-bg" @click.self="isChangeTeamModalOpen = false">
                                    <div class="modal">
                                        <button @click="isChangeTeamModalOpen = false" class="close-btn">x</button>
                                        <Modal_ChangeTeam
                                        v-bind:team="team"
                                        v-bind:permissions="permissions"
                                        v-bind:users="users"
                                        v-bind:users_teamlead="users_teamlead"
                                        v-on:teamChanged="handleTeamChanged"
                                        v-on:teamDeleted="handleTeamDeleted"
                                        />
                                        <!-- v-if="permissions.includes(`olp_change_objective_partly_${objective.id}`) || permissions.includes(`olp_change_objective_all_${objective.id}`)" -->
                                    </div>

                                </div>
                            </Transition>
                        </Teleport>
                    </td>
                    <td class="text-center" v-else-if="permissions.includes(`olp_change_team_checkin_day_${team.id}`)">
                        <button class="actionButton" @click="isChangeTeamCheckinModalOpen = true">Ändern</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isChangeTeamCheckinModalOpen" class="modal-bg" @click.self="isChangeTeamCheckinModalOpen = false">
                                    <div class="modal">
                                        <button @click="isChangeTeamCheckinModalOpen = false" class="close-btn">x</button>
                                        <Modal_ChangeTeamCheckin
                                        v-bind:team="team"
                                        v-bind:permissions="permissions"
                                        v-bind:users="users"
                                        v-bind:users_teamlead="users_teamlead"
                                        v-on:teamChanged="handleTeamChanged"
                                        v-on:teamDeleted="handleTeamDeleted"
                                        />
                                        <!-- v-if="permissions.includes(`olp_change_objective_partly_${objective.id}`) || permissions.includes(`olp_change_objective_all_${objective.id}`)" -->
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </td>
                </tr>
            </table>
        </td>
</template>

<script>
import axios from 'axios'
import Modal_ChangeTeam from '@/components/Modals/Modal_ChangeTeam.vue'
import Modal_ChangeTeamCheckin from '@/components/Modal_ChangeTeamCheckin.vue'


export default {
    name: 'Component_Teams_Team',
    components: {
        Modal_ChangeTeam,
        Modal_ChangeTeamCheckin,
    },
    props: {
        team: Object,
        permissions: {
            type: Array,
            required: true
        },
        users: {
            type: Array,
            required: true
        },
        users_teamlead: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            isChangeTeamModalOpen: false,
            isChangeTeamCheckinModalOpen: false,
        }
    },
    methods: {
        handleTeamChanged() {
            this.isChangeTeamModalOpen = false
            this.isChangeTeamCheckinModalOpen = false
            this.$emit('teamChanged')
        },
        handleTeamDeleted() {
            this.isChangeTeamModalOpen = false
            this.$emit('teamDeleted')
        }
    }
}
</script>
