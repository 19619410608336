<template>
    <div class="flex flex-wrap" v-if="!isLoading">
        <div header class="w-full text-3xl text-center pb-3 mb-10">Firmenübersicht</div>
        <div class="flex items-center justify-center w-full">
            <div class="overview-background bg-black" v-for="vision in visions" v-bind:key="vision.id">
                <Component_Overview_Vision 
                    v-bind:vision="vision"
                    v-bind:missions="missions"
                    v-bind:moals="moals"
                    v-bind:objectives="objectives"
                    v-bind:keyresults="keyresults"
                    v-bind:permissions="permissions"
                    v-on:visionChanged="handleSomethingChanged"
                >
                </Component_Overview_Vision>
            </div>
        </div>
        <div class="w-full">
            &nbsp;
        </div>
        <div class="w-full flex items-center justify-center">
            <table class="table max-w-full no-header-border table-fixed bg-transparent"  v-if="!this.isLoading">
                <tr class="inline-container">
                    <th class="overview-background overview-legend bg-gray-100">
                        <div class="inline-content">
                            <button v-if="permissions.includes('product.add_vision')" @click="isAddVisionModalOpen = true">
                                <Component_Plus_Turn/>    
                            </button>
                            <div class="vertical-center-flex">Vision hinzufügen</div>
                            <Teleport to="#modal">
                                <Transition name="modal">
                                    <div v-if="isAddVisionModalOpen" class="modal-bg" @click.self="isAddVisionModalOpen = false">
                                        <div class="modal">
                                        <button @click="isAddVisionModalOpen = false" class="close-btn">x</button>
                                        <Modal_AddVision
                                        v-if="permissions.includes('product.add_vision')"
                                        v-on:visionAdded="handleSomethingChanged" 
                                        />                    
                                        </div>
                                    </div>
                                </Transition>
                            </Teleport>
                        </div>
                    </th>
                    <th class="overview-background overview-legend bg-gray-100">
                        <div class="inline-content">
                            <button v-if="permissions.includes('product.add_mission')" @click="isAddMissionModalOpen = true">
                                <Component_Plus_Turn/>
                            </button>
                            <div class="vertical-center-flex">Mission hinzufügen</div>
                            <Teleport to="#modal">
                                <Transition name="modal">
                                    <div v-if="isAddMissionModalOpen" class="modal-bg" @click.self="isAddMissionModalOpen = false">
                                        <div class="modal">
                                        <button @click="isAddMissionModalOpen = false" class="close-btn">x</button>
                                        <Modal_AddMission
                                        v-if="permissions.includes('product.add_mission')"
                                        v-on:missionAdded="handleSomethingChanged" 
                                        v-bind:visions="visions"
                                        />                    
                                        </div>
                                    </div>
                                </Transition>
                            </Teleport>
                        </div>
                    </th>
                    <th class="overview-background overview-legend bg-gray-100">
                        <div class="inline-content">
                            <button v-if="permissions.includes('product.add_mission')" @click="isAddMoalModalOpen = true">
                                <Component_Plus_Turn/>
                            </button>
                            <div class="vertical-center-flex">
                                MOAL hinzufügen
                            </div>
                            <Teleport to="#modal">
                                <Transition name="modal">
                                    <div v-if="isAddMoalModalOpen" class="modal-bg" @click.self="isAddMoalModalOpen = false">
                                        <div class="modal">
                                        <button @click="isAddMoalModalOpen = false" class="close-btn">x</button>
                                        <Modal_AddMoal
                                        v-if="permissions.includes('product.add_moal')"
                                        v-on:moalAdded="handleSomethingChanged" 
                                        v-bind:missions="missions"
                                        />                    
                                        </div>
                                    </div>
                                </Transition>
                            </Teleport>
                        </div>
                    </th>
                </tr>
            </table>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

import Component_Overview_Vision from '@/components/Overview/Component_Overview_Vision'
import Modal_AddVision from '@/components/Modals/Modal_AddVision'
import Modal_AddMission from '@/components/Modal_AddMission'
import Modal_AddMoal from '@/components/Modals/Modal_AddMoal'
import Component_Plus_Turn from '@/components/ButtonIcons/Component_Plus_Turn'


export default {
    name: 'Overview',
    components:
    {
        Component_Overview_Vision,
        Component_Plus_Turn,
        Modal_AddVision,
        Modal_AddMission,
        Modal_AddMoal,
    },
    props: {
        permissions: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            visions: [],
            missions: [],
            moals: [],
            objectives: [],
            keyresults: [],
            // permissions: [],
            isAddVisionModalOpen: false,
            isAddMissionModalOpen: false,
            isAddMoalModalOpen: false,
            isLoading: true,
        }
    },
    mounted() {
        this.loadData();
        // this.getVisions();
        // this.getMissions();
        // this.getMOALs();
        // this.getObjectives();
        // this.getKeyResults();
        // this.fetchPermissions();
    },
    computed: {
        ...mapState({
            isLoading: state => state.isLoading
        }),
        permissions() {
            return this.$store.state.permissions;
        },
    },
    methods: { 
        async loadData() {
            this.$store.commit('setIsLoading', true);
            try {
                await Promise.all([
                    this.getVisions(), 
                    this.getMissions(), 
                    this.getMOALs(), 
                    this.getObjectives(), 
                    this.getKeyResults(), 
                    this.$store.dispatch('fetchPermissions')
                    // this.fetchPermissions()
                ])
                this.isLoading = false
                console.log('data:', "Visions", this.visions, "Missions", this.missions, "MOALs", this.moals, "Objectives", this.objectives, "Key Results", this.keyresults, "Permissions", this.permissions);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }  
        },
        async getVisions() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/visions/');
                this.visions = response.data;
                console.log('visions:', this.visions);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        async getMissions() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/missions/');
                this.missions = response.data;
                console.log('missions:', this.missions);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getMOALs() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/moals/');
                this.moals = response.data;
                console.log('moals:', this.moals);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getObjectives() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/objectives/');
                this.objectives = response.data;
                console.log('objectives:', this.objectives);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async getKeyResults() {
            // this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/keyresults/');
                this.keyresults = response.data;
                // console.log('keyresults:', this.keyresults);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // this.$store.commit('setIsLoading', false);
        },
        async fetchPermissions() {
            try {
                const response = await axios.get('/api/v1/users/me/permissions'); 
                this.permissions = response.data.permissions;
                // console.log('permissions:', this.permissions);
            } catch (error) {
            }
        },
        async handleSomethingChanged() {
            this.isAddVisionModalOpen = false;
            this.isAddMissionModalOpen = false;
            this.isAddMoalModalOpen = false;
            await this.loadData();
            


            // this.isChangeVisionModalOpen = false;
            // this.isChangeMissionModalOpen = false;
            // this.isChangeMoalModalOpen = false;
            // this.$store.dispatch('fetchData');
        },
    }
}


</script>

<style>
.overview-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px; /* Adjust padding as needed */

    margin-top: 1rem;
    width: auto;
    height: auto;
}
.inline-container {
    display: flex;
    align-items: center; /* Adjust vertical alignment as needed */
    justify-content: space-between; /* Adjust this as per your layout needs */
}
.inline-content{
    display: flex;
    align-items: center; /* This ensures vertical alignment if needed */
    /* margin-left: 8px;  */
}

</style>