<template> 
    <td v-if="isObjectiveFromActiveWorkingPeriod && permissions.includes(`olp_change_objective_${objective.id}`)" class="vertical-center"> 
        <button @click="this.restoreObjective()" class="actionButton">Wiederherstellen</button>
    </td>

    <!-- <td class="vertical-center" v-if="isPlanningActive && !isObjectiveFromActiveWorkingPeriod"> 
        <button v-if="permissions.includes(`olp_change_objective_${objective.id}`)" @click="isChangeObjectiveModalOpen = true" class="button is-ghost">Für Planning kopieren</button> -->
       

    <td class="vertical-center" v-if="isPlanningActive && !isObjectiveFromActiveWorkingPeriod && permissions.includes('product.add_objective')"> 
        <button @click="isChangeObjectiveModalOpen = true" class="actionButton">Für Planning kopieren</button>
        <Teleport to="#modal">
            <Transition name="modal">
                <div v-if="isChangeObjectiveModalOpen" class="modal-bg" @click.self="isChangeObjectiveModalOpen = false">
                    <div class="modal">
                        <button @click="isChangeObjectiveModalOpen = false" class="close-btn">x</button>
                        <Modal_AddObjective_Planning 
                        v-bind:objective="objective"
                        v-bind:keyresults="filteredKeyresults"
                        v-bind:activeWorkingPeriods="activeWorkingPeriods"
                        v-on:objectiveAdded="handleObjectiveAdded"
                        v-on:objectiveDeleted="handleObjectiveAdded"
                        v-on:keyresultChanged="handleObjectiveAdded"
                        />
                        <!-- v-if="permissions.includes(`olp_change_objective_partly_${objective.id}`) || permissions.includes(`olp_change_objective_all_${objective.id}`)" -->
                    </div>
                </div>
            </Transition>
        </Teleport>
    </td>
</template>

<script>
import Modal_AddKeyresult from '@/components/Modals/Modal_AddKeyresult'
import Modal_ChangeObjective from '@/components/Modals/Modal_ChangeObjective.vue'
import Modal_AddObjective_Planning from '@/components/Modals/Modal_AddObjective_Planning.vue'

import axios from 'axios'

export default {
    name: 'Component_OKR_Objective_ActionsArchive',
    emits: ['ObjectiveAdded','objectiveChanged'],
    components:
    {
        Modal_AddKeyresult,
        Modal_ChangeObjective,
        Modal_AddObjective_Planning,
    },
    props: {
        objective: Object,
        keyresults: Array,
        filteredKeyresults: Array,
        activeWorkingPeriods: Array,
        permissions: Array,
        isPlanningActive: Boolean,
        permissions: Array,
    },
    data() {
        return {
            isChangeObjectiveModalOpen: false,
            isObjectiveFromActiveWorkingPeriod: false,
        }
    },
    mounted() {
        this.compareWorkingPeriods();
        // console.log('this.objective', this.objective);
        // console.log('isPlanningActive', this.isPlanningActive);
        // console.log('permissions', this.permissions);
        // console.log('this.activeWorkingPeriods', this.activeWorkingPeriods);
    },
    watch: {
        objective: {
            handler() {
                this.compareWorkingPeriods();
            },
            deep: true
        },
        activeWorkingPeriods: {
            handler() {
                this.compareWorkingPeriods();
            },
            deep: true
        }
    },
    methods: {
        compareWorkingPeriods() {
            // console.log('compare working periods:', this.objective.working_period, this.activeWorkingPeriods);
            if (!this.activeWorkingPeriods || !Array.isArray(this.activeWorkingPeriods)) {
                this.isObjectiveFromActiveWorkingPeriod = false;
                // console.log('isObjectiveFromActiveWorkingPeriod', this.isObjectiveFromActiveWorkingPeriod);
                return;
            }
            this.isObjectiveFromActiveWorkingPeriod = this.activeWorkingPeriods.some(period => period.id === this.objective.working_period);
            // console.log('isObjectiveFromActiveWorkingPeriod', this.isObjectiveFromActiveWorkingPeriod);

            // console.log('this.activeWorkingPeriods.some(period => period.id === this.objective.working_period)', this.activeWorkingPeriods.some(period => period.id === this.objective.working_period))
        },
        
        async restoreObjective() {
            this.$store.commit('setIsLoading', true)

            await axios
                .patch(`/api/v1/objectives-archived/${this.objective.id}/`, 
                {
                is_archived: false,
                },
                )
                .then(response => {
                // console.log('Response:', response.data);
                this.$emit('objectiveChanged');
                })
                .catch(error => {
                    console.error('Error restoring objective:', error);
                });
            this.$store.commit('setIsLoading', false)

        },
        handleObjectiveAdded() {
            this.$emit('objectiveAdded');
            this.isChangeObjectiveModalOpen = false;
        }
    }
}
        

</script>