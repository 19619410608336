<template>
    <div class="notification is-info mt-4 text-center" v-if="!isPlanningActive">
        Achtung: Sie machen gerade eine Eintragung <br>
        außerhalb der dafür vorgesehenen Planningzeit.
    </div>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Add new Objective
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <input type="text" class="input-field-big" placeholder="New objective" v-model="name">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Team:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="team"> 
                            <option v-for="team in teamsTeamLead" :key="team.id" :value="team.id">{{ team.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="status">
                            <!-- <option value="" disabled selected>Status</option> -->
                            <option value="Not started">Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Moals:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" multiple v-model="selectedMoals">
                            <option v-for="moal in moals_fetched" :value="moal.id">{{ moal.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="selectedMoals && selectedMoals.length > 0">
                <td class="vertical-center">
                    <strong>
                        Gewichte MOALs:
                    </strong>
                </td>
            </tr>
            <tr v-for="(moalId, index) in selectedMoals" :key="index">
                <td class="vertical-center">{{ findMoalName(moalId) }}:</td>
                <td class="vertical-center">
                    <div>
                        <input type="number" class="input-field-big" v-model="moalsWeights[moalId]" />
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Grün (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="threshold_green" @input="validatePercentage('threshold_green')">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Rot (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="threshold_red" @input="validatePercentage('threshold_red')">
                    </div>
                </td>
            </tr>

            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="radio-group">
                        <div v-for="period in activeWorkingPeriods" :key="period.id" class="radio-option">
                            <input
                            type="radio"
                            :value="period"
                            :id="`period-${period.value}`"
                            v-model="selectedWorkingPeriod"
                            name="workingPeriod"
                            >
                            <label :for="`period-${period.value}`" class="spaced"> {{ period.name }}</label>
                        </div>
                    </div>
                </td> 
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Modal_AddObjective',
    emits: ['objectiveAdded'],
    props: {
        activeWorkingPeriods: Array,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            name: 'Default Objective',
            team_users_ids: [], 
            status: 'Not started',
            errors: [],
            team: '',
            // team: this.teamsTeamLead[0].id,
            teams: [],
            moals_fetched: [],
            selectedMoals: [],
            moalsWeights: {},
            threshold_green: 70,
            threshold_red: 30,
            selectedWorkingPeriod: this.activeWorkingPeriods[0],
            customSelected: false,
            showHelpText: false,
        }
    },
    mounted() {
        // this.getUsers();
        this.fetchTeams();
        this.getMOALs();
    },
    computed: {
        ...mapState([
            'teamsTeamLead',
        ])
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.name === '') {
                this.errors.push('The name field is missing!')
            }
            // this.team = '';
            if (this.team === '') {
                this.errors.push('The team field is missing!')
            }
            // if (this.unit === '') {
            //     this.errors.push('The progress unit is missing!')
            // }
            if (this.status === '') {
                this.errors.push('The status field is missing!')
            }
            if (this.selectedWorkingPeriod.length === '') {
                this.errors.push('The working period field is missing!')
            }

            if (this.threshold_red >= this.threshold_green) {
                this.errors.push('Rot muss den niedrigsten und grün den höchsten Wert haben')
            }
            // if (this.moals.length === 0) {
            //     this.errors.push('The Moals field is missing!')
            // }
            if (!this.errors.length) {
                this.progress = parseInt(this.progress);
                if (this.unit === 'custom') {
                    this.unit = this.customInput
                };
                this.addObjective();
                this.unit = '%';
                // this.user = localStorage.getItem('user')
            }
        },
        async addObjective() {
            const payload = {
                name: this.name,
                team: this.team, 
                owner: this.owner,
                status: this.status,
                moals: this.selectedMoals,
                multiplier_objective_moal: this.prepareMoalsForSubmission(),
                working_period: this.selectedWorkingPeriod.id,
                threshold_green: this.threshold_green,
                threshold_red: this.threshold_red,
            }
            this.$store.commit('setIsLoading', true)
            // console.log('Adding objective:', this.name, this.progress, this.progress_unit, this.status, this.team_users_ids)
            // console.log("multiplier_objective_moal:", payload.multiplier_objective_moal)
            // console.log("selected workingPeriod:", payload.workingPeriod)
            // console.log("label workingPeriod:", this.selectedWorkingPeriod.value)
            await axios
                .post('/api/v1/objectives/', payload,)
                .then(response => {
                // console.log('Response:', response.data);
                this.$emit('objectiveAdded');
                // console.log('Objective added was emitted:', response.data);
                })
                .catch(error => {
                    if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        },
        fetchTeams() {
            this.$store.dispatch('fetchTeamsTeamlead')
                .then(() => {
                });
        },
        // async getUsers() {
        //     try {
        //         const response = await axios.get(`/api/v1/users/`)
        //         this.users = response.data
        //         // console.log("users:", this.users);
        //     } catch (error) {
        //         console.error('Error getting username:', error)
        //     }
        // },
        async getMOALs() {
            // console.log('vision', this.vision)
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get(`/api/v1/moals/`);
                this.moals_fetched = response.data;
                console.log('MOALs:', this.moals_fetched)

            } catch (error) {
                console.error('Error fetching moals:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        findMoalName(id) {
            const moal = this.moals_fetched.find(moal => moal.id === id);
            return moal ? moal.name : 'Unknown';
        },
        prepareMoalsForSubmission() {
            const connections = [];
            for (const moalId in this.moalsWeights) {
                connections.push({
                    moal: moalId,
                    weight: this.moalsWeights[moalId]
                    // objective: this.objective.id
                });
            }
            return connections;
        },
        validatePercentage(field) {
            if (this[field] < 0) {
                this.field = 0;
            } else if (this[field] > 100) {
                this[field] = 100;
            }
        },
    },
    watch: {
    selectedMoals(newVal, oldVal) {
      // Initialize weight for newly added moals
      newVal.forEach(id => {
        if (!this.moalsWeights[id]) {
            this.moalsWeights[id] = 1; // Default weight of 1
        }
      });
      // Remove weight for deselected moals
      for (const id in this.moalsWeights) {
        if (!newVal.includes(Number(id))) {
            delete this.moalsWeights[id];
        }
      }
    }
  }
}
</script>

<style scoped>


</style>