<template>

    <!-- ############################### Action buttons ################# -->
    <!-- <tr class="tableRow" :class="{'has-background-white': this.$store.state.theme == 'light', 'has-background-dark': this.$store.state.theme == 'dark'}"> -->
    <tr class="tableRow">
        <td class="okrFirstColumn verticalCenterTD">
            <button 
                v-if="!isArchive && permissions.includes(`olp_change_objective_${objective.id}`) && permissions.includes('product.add_keyresult')" 
                @click="isAddKeyresultModalOpen = true" 
                class="addKeyresultButton border"

                >
                <PlusIcon class="h-3.5 w-3.5" />
                <!-- class="button is-small is-grey" -->
            </button>
            <Teleport to="#modal">
                <Transition name="modal">
                    <div v-if="isAddKeyresultModalOpen" class="modal-bg" @click.self="isAddKeyresultModalOpen = false">
                        <div class="modal">
                            <Component_Modal_InfoButton :infoText="infotextAddKeyresult" />
                            <button @click="isAddKeyresultModalOpen = false" class="close-btn">x</button>
                            <Modal_AddKeyresult
                                v-bind:objective="objective"
                                v-bind:team_users="team_users"
                                v-bind:isPlanningActive="isPlanningActive"
                                v-on:keyResultAdded="handlekeyresultAdded" 
                            />
                        </div>
                    </div>
                </Transition>
            </Teleport>
        </td>
        <td class="okrSecondColumn verticalCenterTD">
            <button v-if="this.filteredKeyresults.length"  @click="showKeyResults = !showKeyResults" class="p-[2px] h-[20px]">
                <span v-if="!showKeyResults"  class="inline-block text-[12px] post-rotate">&#9660;</span>
                <span v-else                  class="inline-block text-[12px] pre-rotate">&#9660;</span>
            </button>
        </td>

        <!-- ############################### Name ############################# -->
        <td class="verticalCenterTD text-left">
            <button class="text-left whitespace-normal" @click="isDetailModalOpen = true">
                <strong class="text-gray-900 dark:text-gray-100">{{ objective.name }}</strong>
            </button>
        </td>

        <!-- ########################## Team ######################### -->
        <td class="verticalCenterTD text-center">
            <!-- <div v-if="!isEditing" v-for="username in product.team_usernames">{{ username }}</div> -->
            <div class="text-gray-900 dark:text-gray-100">{{ objective.team_name }}</div>
        </td> 

        <!--  ################################## Owner ############################## -->
        <td class="verticalCenterTD text-center  text-gray-900 dark:text-gray-100">{{ objective.creator_name }}</td>

        <!-- ##################################### Progress ################################ -->
        <td v-if="this.filteredKeyresults.length > 0" class="verticalCenterTD text-center">
            <div class="flex-row-wrapper">
                <div class="progress-bar-container bg-gray-300">
                    <!-- <div class="progress-bar-filled text-center" :style="{ width: this.objectivePercentage + '%', backgroundColor: progressBarColor}"></div>                    <span class="progress-value">{{ objective.progress }} %</span>  -->

                    <div class="progress-bar-filled text-center" :class="[progressBarWidthClass, progressBarFrontColorClass]"></div>                    
                    <span class="progress-value text-gray-900">{{ objective.progress }} %</span> 
                </div>
            </div>
        </td>
        <td v-else></td>

        <!-- ####################################### Working period ############################## -->
        <td class="verticalCenterTD text-center text-gray-900 dark:text-gray-100">{{ objective.working_period_name }}</td>
        
        <!-- ::::::::: Change Objective Modal ::::::::: -->
        <!-- TODO: check not only here but also in the view -->
         <!-- ##################################### Actions ############################### -->
        <td class="align-middle">

        <!-- <td class="verticalCenterTD text-center">  -->
            <table class="w-full border-collapse">
                <tr>
                    <Component_OKR_Objective_ActionsNormal
                        v-if="!isArchive"
                        v-bind:objective="objective"
                        v-bind:keyresults="keyresults"
                        v-bind:filteredKeyresults="filteredKeyresults"
                        v-bind:activeWorkingPeriods="activeWorkingPeriods"
                        v-bind:permissions="permissions"
                        v-bind:isPrior="isPrior"
                        v-bind:isPlanningActive="isPlanningActive"
                        v-on:objectiveChanged="handleObjectiveChanged"
                        v-on:keyresultChanged="handleKeyresultChanged"
                        v-on:objectiveAdded="handleObjectiveChanged"
                    />
                    <Component_OKR_Objective_ActionsArchive
                        v-if="isArchive"
                        v-bind:objective="objective"
                        v-bind:keyresults="keyresults"
                        v-bind:filteredKeyresults="filteredKeyresults"
                        v-bind:activeWorkingPeriods="activeWorkingPeriods"
                        v-bind:permissions="permissions"
                        v-bind:isPlanningActive="isPlanningActive"
                        v-on:objectiveAdded="handleObjectiveChanged"
                        v-on:objectiveChanged="handleObjectiveChanged"
                        v-on:objectiveDeleted="handleObjectiveChanged"
                        v-on:keyresultChanged="handleKeyresultChanged"
                    />
                </tr>
            </table>
        </td>
    </tr>

    <!-- ########################### Keyresult Component ############################ -->
    <tr class="tableRow" v-if="showKeyResults" v-for="keyresult in filteredKeyresults" v-bind:key="keyresult.id">
        <Component_OKR_Keyresult
                v-bind:keyResult="keyresult"
                v-bind:permissions="permissions"
                v-bind:team_users="team_users"
                v-bind:isArchive="isArchive"
                v-bind:threshold_green="objective.threshold_green"
                v-bind:threshold_red="objective.threshold_red"
                v-bind:isPlanningActive="isPlanningActive"
                v-on:keyresultAdded="handlekeyresultAdded"
                v-on:keyresultDeleted="handlekeyresultDeleted" 
                v-on:keyresultUpdated="handleKeyresultChanged" 
                >
        </Component_OKR_Keyresult>
    </tr>


</template>

<script>
import axios from 'axios'
import Component_OKR_Keyresult from '@/components/OKR/Component_OKR_Keyresult'
import Modal_AddKeyresult from '@/components/Modals/Modal_AddKeyresult'
import { mapState } from 'vuex'
// import Modal_ChangeObjective from './Modal_ChangeObjective.vue'
import Modal_AddObjective_Planning from '@/components/Modals/Modal_AddObjective_Planning'
import Component_OKR_Objective_ActionsNormal from '@/components/OKR/Component_OKR_Objective_ActionsNormal'
import Component_OKR_Objective_ActionsArchive from '@/components/OKR/Component_OKR_Objective_ActionsArchive'
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton'

import { progressBarBackgroundColor } from '@/utils/utils.js';
import { progressBarColor } from '@/utils/utils.js';
import { calculateObjectivePercentage, calculateKeyresultProgress } from '@/utils/utils.js';

import { PlusIcon } from '@heroicons/vue/24/outline';




export default {
    name: 'Component_OKR_Objective',
    emits: ['objectiveAdded', 'objectiveDeleted', 'objectiveChanged'],
    components:
    {
        Component_OKR_Keyresult,
        Component_OKR_Objective_ActionsNormal,
        Component_OKR_Objective_ActionsArchive,
        Component_Modal_InfoButton,
        Modal_AddKeyresult,
        // Modal_ChangeObjective,
        Modal_AddObjective_Planning,
        PlusIcon,
        // Modal_Objective_Detail,
    },
    props: {
        objective: Object,
        keyresults: Array,
        activeWorkingPeriods: Array,
        isPrior: Boolean,
        isPlanningActive: Boolean,
        isArchive: Boolean,
        permissions: {
            type: Array,
            required: true
        }
    },
    watch: {
      keyresults(newValue, oldValue) {
        this.filterKeyResults();
      }
    },
    data() {
        return {
            added_team_member: null,
            team_users: [],
            objectiveProgress: '',
            objectivePercentage: '',
            // editedObjective: { ...this.objective },
            isEditing: false,
            // username: null,
            customInput: '',
            customSelected: false,
            filteredKeyresults: [],
            showKeyResults: true,
            // teams: [],
            teamName: '',
            isAddKeyresultModalOpen: false,
            // isChangeObjectiveModalOpen: false,
            // isAddObjectivePlanningModalOpen: false,
            isDetailModalOpen: false,
            infotextAddKeyresult: "Keyresults sollten immer genau definiert und messbar sein"
        }
    },
    mounted() {
        if (this.isArchive) {
            this.showKeyResults = false;
        }
        this.getTeamUsers();
        this.filterKeyResults();
        // this.filteredKeyresults = this.filterKeyResults.map
            
        // calculateKeyresultProgress(this.filteredKeyresults);
        this.calculateKeyresultProgresses();
        this.calculateObjectiveProgress();
        this.objectivePercentage = calculateObjectivePercentage(this.filteredKeyresults);
     //   this.getkeyresults();
    //   this.fetchTeams();
    },

    computed: {
        ...mapState([
            'teamsTeamLead',
            'teamsAll'
        ]),
        progressBarFrontColorClass() {
            return progressBarColor(this.objectiveProgress, this.objective.threshold_green, this.objective.threshold_red);
        },
        backgroundColorComputed() {
            return progressBarBackgroundColor(this.objectiveProgress, this.objective.threshold_green, this.objective.threshold_red);
        },
        progressBarWidthClass() {
            // console.log("this.objective", this.objective);
            const percentage = Math.round(this.objective.progress);
            // console.log('percentage:', this.percentage);
            // console.log('percentage:', `w-[${percentage}%]`);
            // return `w-[${percentage}%]`;
            return `w-${Math.min(Math.max(Math.round(percentage / 5) * 5, 0), 100)}pc`;

            // return 'w-[10%]';
        },
    },
    methods: {
        filterKeyResults() {
            this.filteredKeyresults = this.keyresults.filter(keyresult => keyresult.objective === this.objective.id);
            // console.log("filteredKeyresults", this.filteredKeyresults)
        },
        // getBackgroundColor() {
        //     return progressBarBackgroundColor(this.objectiveProgress);
        // },
        async calculateKeyresultProgresses() {
            this.filteredKeyresults.forEach((keyresult, index) => {
                keyresult = calculateKeyresultProgress(keyresult); 
                // console.log("keyresult.progressii:", keyresult.progress);
            });
        },

        calculateObjectiveProgress() {
            let progress = 0;
            let totalProgress = 0;
            let totalKeyresults = 0;
            this.filteredKeyresults.forEach(keyresult => {
                // console.log("keyresult.progress:", keyresult.progress)
                totalProgress += keyresult.progress;
                totalKeyresults += 1;
            });
            if (totalKeyresults > 0) {
                progress = totalProgress / totalKeyresults;
            }
            this.objectiveProgress = progress;
            // console.log("objective:",  this.objective)
            // console.log("objectiveProgress Objective:",  this.objectiveProgress)
        },
        async deleteProduct() {
            this.$store.commit('setIsLoading', true)
            await axios
                .delete(`/api/v1/objectives/${this.objective.id}`)
                .then(response => {
                    // console.log('Response:', response.data);
                    // Emit an event to inform the parent component
                    this.$emit('objectiveDeleted', this.objective.id);
                })
                .catch(error => {
                    console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
            
        },
        async getTeamUsers() {
            if (this.objective.team === null) {
                return;
            }
            try {
                const response = await axios.get(`/api/v1/team-users?team_id=${this.objective.team}`)
                this.team_users = response.data
                // console.log("team_users:", this.team_users);
            } catch (error) {
                console.error('Error getting Team users:', error)
            }
        },
        async handleKeyresultChanged() {
            // Fetch objectives again after a objective is deleted
            // await this.getkeyresults();
            this.$emit('objectiveAdded');
        },
        async handlekeyresultAdded() {
            // Fetch objectives again after a objective is deleted
            // await this.getkeyresults();
            this.isAddKeyresultModalOpen = false;
            this.$emit('objectiveAdded');
        },
        async handlekeyresultDeleted() {
            // await this.getkeyresults();
            this.$emit('objectiveAdded');

        },
        async handleObjectiveChanged() {
            this.$emit('objectiveAdded');
            this.isChangeObjectiveModalOpen = false;
        }
    }
}
</script>


<style scoped>
.post-rotate {
    transform: rotate(270deg) scaleX(1.2)
}
.pre-rotate {
    transform: scaleX(1.2)
}


.objective-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  /* margin-bottom: 100px; */
}

.info-column {
  flex: 1;
  margin-right: 10px;
}


.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  background-color: #000; /* Your desired background color */
  border-radius: 50%;
}

.chevron-up {
  color: #fff; /* Your desired chevron color */
}





</style>