<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Neues Planning
    </strong>
    <table>
        <tbody>
            <!-- <tr>Planning Zeitraum</tr> -->
            <tr>
                <td class="vertical-center">Beginn:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="start_planning">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Ende:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="end_planning">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="checkbox-group">
                        <div v-for="period in workingPeriods" :key="period.value" class="checkbox-option">
                            <input
                                type="checkbox"
                                :id="`quarter-${period.value}`"
                                :value="period"
                                v-model="selectedPeriods"
                                :checked="isPeriodSelected(period)"
                            >
                            <label :for="`quarter-${period.value}`">{{ period.label }}</label>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Planning starten</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Modal_AddObjective',
    emits: ['planningAdded', 'workingPeriodsAdded'],
    data() {
        return {
            start_planning: this.getToday(),
            end_planning: this.getInThreeWeeks(),
            workingPeriods: this.generateWorkingPeriods(),
            selectedPeriods: [], 
            // workingPeriods: '',
            errors: [],
            showHelpText: false,
        }
    },
    mounted() {
        this.selectedPeriods = this.workingPeriods;
        console.log('workingPeriods:', this.workingPeriods);
    },
    methods: {
        getToday() {
            const today = new Date();
            const year = today.getFullYear();
            const month = (`0${today.getMonth() + 1}`).slice(-2); // Add leading zero if needed
            const day = (`0${today.getDate()}`).slice(-2); // Add leading zero if needed
            // Set time to "00:00" to comply with the "datetime-local" input format
            const time = 'T00:00';
            return `${year}-${month}-${day}${time}`;
        },
        getInThreeWeeks() {
            const today = new Date();
            // Calculate 3 weeks in milliseconds
            const threeWeeksInMs = 3 * 7 * 24 * 60 * 60 * 1000;
            // Add 3 weeks to the current date
            const futureDate = new Date(today.getTime() + threeWeeksInMs);
            const year = futureDate.getFullYear();
            const month = (`0${futureDate.getMonth() + 1}`).slice(-2); // Add leading zero if needed
            const day = (`0${futureDate.getDate()}`).slice(-2); // Add leading zero if needed
            // Assuming you want to keep the time as "00:00" for consistency
            const time = 'T00:00';
            return `${year}-${month}-${day}${time}`;
        },
        generateWorkingPeriods() {
            const workingPeriods = [];
            // const currentDate = new Date('2024-05-10');
            const currentDate = new Date();
            console.log('currentDate:', currentDate);

            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1; // 1-12
            const currentDay = currentDate.getDate(); // 1-31
            console.log('currentyear:', currentYear, 'currentMonth:', currentMonth, 'currentDay:', currentDay);

            // Calculate the day of the year
            const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (isLeapYear(currentYear)) {
            daysInMonth[1] = 29;
            }

            let dayOfYear = currentDay;
            for (let i = 0; i < currentMonth - 1; i++) {
            dayOfYear += daysInMonth[i];
            }

            // Calculate the closest quarter
            const daysInQuarter = 365 / 4; // Assumption
            const closestQuarter = Math.round(dayOfYear / daysInQuarter) % 4 + 1;
            const closestYear = dayOfYear >= 365 - daysInQuarter / 2 ? currentYear + 1 : currentYear;

            console.log('dayOfYear:', dayOfYear);
            console.log('closestQuarter:', closestQuarter);
            console.log('closestYear:', closestYear);

            // const closestQuarter = ((Math.round(currentMonth / 3)) + 1) % 4;
            // (closestQuarter - 1) * 3
            const closestQuarterStartMonth = (closestQuarter - 1) * 3 + 1;
            const closestQuarterStartDate = new Date(closestYear, closestQuarterStartMonth, 1);
            console.log('closestQuarterStartDate:', closestQuarterStartDate);

            console.log('closestQuarter:', closestQuarter);
            console.log('closestYear:', closestYear);

            // Determine the semester based on the current quarter
            // const currentSemester = closestQuarter <= 2 ? 1 : 2;
            // const semesterStartDate = new Date(currentYear, currentSemester === 1 ? 0 : 6, 1);

            

            // Calculate the year span based on the current quarter
            // Correctly calculate the end date for the year span to be the end of the same quarter next year

            // Add periods to workingPeriods
            workingPeriods.push({
                label: ` Q${closestQuarter} ${closestYear}`,
                // value: `${currentYear}-Q${currentQuarter}`,
                startDate: this.formatDate(closestQuarterStartDate),
                // Adjust endDate calculation for the quarter to correctly represent the end of the quarter
                endDate: this.formatDate(new Date(closestQuarterStartDate.getFullYear(), closestQuarterStartDate.getMonth() + 3, 1))
            });

            let yearSixMonthsLater = closestYear;
            if (closestQuarter >= 3) {
                yearSixMonthsLater = currentYear + 1;
            }
            const semesterEndDate = new Date(yearSixMonthsLater, (closestQuarterStartMonth + 6) % 12 , 1);
            workingPeriods.push({
                // label: ` S${closestQuarter} ${currentYear}`,
                label: ` Q${closestQuarter} ${closestYear} - Q${(closestQuarter + 1) % 4} ${yearSixMonthsLater}`, 
                // value: `${currentYear}-S${currentSemester}`,
                startDate: this.formatDate(closestQuarterStartDate),
                // Adjust endDate calculation for the semester to correctly represent the end of the semester
                endDate: this.formatDate(semesterEndDate)
            });

            const yearSpanEndDate = new Date(closestYear + 1, closestQuarterStartMonth, 1); 
            workingPeriods.push({
                label: ` Q${closestQuarter} ${closestYear} - Q${(closestQuarter + 2) % 4 + 1} ${currentYear + 1}`,
                // value: `${currentYear}-Y`,
                startDate: this.formatDate(closestQuarterStartDate),
                endDate: this.formatDate(yearSpanEndDate)
            });
            console.log('workingPeriods after push:', workingPeriods);

            return workingPeriods;
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = date.getMonth().toString().padStart(2, '0'); 
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        isPeriodSelected(period) {
            const isSelected = this.selectedPeriods.some(selected => selected.value === period.value);
            // console.log(`Period ${period.value} selected:`, isSelected);
            return isSelected;
        },
        submitForm() {
            this.errors = []

            if (this.start_planning === '') {
                this.errors.push('The start field is missing!')
            }
            
            if (this.end_planning === '') {
                this.errors.push('The end field is missing!')
            }
            if (this.selectedPeriods.length === 0) {
                this.errors.push('Please select at least one working period!')
            }
            if (!this.errors.length) {
                
                this.addPlanning();
                // this.addWorkingPeriods();
                // this.user = localStorage.getItem('user')
            }
        },
        async addPlanning() {
            this.$store.commit('setIsLoading', true);

            // Construct the payload for planning
            const planningPayload = {
                start: this.start_planning,
                end: this.end_planning,
                workingPeriods: []  // Initialize an array to hold working periods data
            };

            // Add working periods data to the payload
            for (const period of this.workingPeriods) {
                // console.log('period:', period, "workingPeriods:", this.workingPeriods);
                // console.log('period:', period);
                if (this.isPeriodSelected(period)) {
                    // console.log('active period:', period);
                    planningPayload.workingPeriods.push({
                    start: period.startDate,
                    end: period.endDate,  
                    is_active: true, 
                    name: period.label,
                    });                
                }
                else {
                    planningPayload.workingPeriods.push({
                    start: period.startDate,
                    end: period.endDate,   
                    name: period.label,
                    is_active: false,                
                });
                }

            }


            // Send a single PUT request with the combined payload
            await axios
                .post('/api/v1/plannings/', planningPayload)
                .then(response => {
                    // Handle success
                    // console.log('Planning and working periods added:', response.data);
                })
                .catch(error => {
                    // Handle error
                    if (error.response) {
                        console.log(error.response.data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });

            // Reset loading state
            this.$store.commit('setIsLoading', false);
            this.$emit('planningAdded');
            this.$emit('workingPeriodsAdded');
        }
    }
}
</script>

<style scoped>


</style>