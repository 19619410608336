import { createStore } from 'vuex'
import axios from 'axios'
// import { set } from 'core-js/core/dict'
// import { is } from 'core-js/core/object'

export default createStore({
  state: {
    // cart: {
    //   items: [],
    // },
    teamsAll: [],
    teamsTeamLead: [],
    isAuthenticated: false,
    token: '',
    isLoading: false,
    theme: 'light',
    permissions: [],
    // currentUser: null,
  },
  getters: {
    hasPermission: (state) => (permission) => {
      return state.permissions.includes(permission);
    },
  },
  mutations: {
    initializeStore(state) {
      // if (localStorage.getItem('cart')) {
      //   state.cart = JSON.parse(localStorage.getItem('cart'))
      // } else {
      //   localStorage.setItem('cart', JSON.stringify(state.cart))
      // }

      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      }
      if (localStorage.getItem('permissions')) {
        state.permissions = JSON.parse(localStorage.getItem('permissions'));
      }
    },
    setTeamsTeamLead(state, teams) {
      state.teamsTeamLead = teams
    },
    setTeamsAll(state, teams) {
      state.teamsAll = teams
    },
    // setPermissions(state, permissions) {
    //   state.permissions = permissions
    // },
    setPermissions(state, permissions) {
      state.permissions = permissions;
      localStorage.setItem('permissions', JSON.stringify(permissions));
    },
    // SET_CURRENT_USER(state, user) {
    //   state.currentUser = user;
    // },
    // addToCart(state, item) {
    //   const exists = state.cart.items.filter(i => i.product.id === item.product.id)

    //   if (exists.length) {
    //     exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
    //   } else {
    //     state.cart.items.push(item)
    //   }

    //   localStorage.setItem('cart', JSON.stringify(state.cart))
    // },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
  },
  actions: {
    async fetchPermissions({ commit, state}) {
      commit('setIsLoading', true)
      try {
        const response = await axios.get('/api/v1/me/permissions');
        // console.log('permissions index function:', response.data.permissions);
        commit('setPermissions', response.data.permissions);

        console.log('permissions in store:', state.permissions); // Log the permissions from the state
      } catch (error) {
        console.error('Error fetching permissions:', error);
      } finally {
        commit('setIsLoading', false);
      }
    },
    async fetchTeamsTeamlead({ commit }) {
      // console.log('isTeamLead:', isTeamLead);
      commit('setIsLoading', true);
      // ItTeamLead = isTeamLead;
      try {
        const response = await axios.get('/api/v1/teams', {
          params: {
            teamlead_check: true,
          },
        })
        // console.log('store response:', response);
        commit('setTeamsTeamLead', response.data);
        // console.log('setTeams done. response.data:', response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        commit('setIsLoading', false);
      }
    },
    async fetchTeamsAll({ commit }) {

      commit('setIsLoading', true);
      // ItTeamLead = isTeamLead;
      try {
        const response = await axios.get('/api/v1/teams', {
          params: {
            teamlead_check: false,
          },
        })
        commit('setTeamsAll', response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        commit('setIsLoading', false);
      }
    },
  },
  modules: {
  }
})
