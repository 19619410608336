<template>
  <div class="main-wrapper" v-if="!isLoading">
    <div >
      <div v-if="isPlanningActive" class="bg-blue-400 border-0 text-gray-100 mb-8 px-4 py-3 rounded">
        Planning ist aktiv! Teamleads bitte OKRs eintragen. Planningzeitraum: {{ formatDate(this.planning.start) }} - {{ formatDate(this.planning.end) }}.
      </div>
    </div>
    <div>
      <button v-if="permissions.includes('product.add_planning') && isPlanningActive === true"  @click="isChangePlanningModalOpen = true" class="actionButton">Planning bearbeiten</button>
    </div>
    <h1 v-if="isPlanningActive">Aktuelle OKRs</h1>
    <h1 v-else>Aktuelle OKRs</h1>

    <div>
      <input type="checkbox" class="mb-6 " v-model="showNotMember"/>
      <span class="ml-2 text-gray-900 dark:text-gray-100">Objectives anderer Teams anzeigen</span>
    </div>

    <!-- <table :class="tableClasses" class="table-fixed" v-if="!isLoading"> -->
    <!-- <table class="table is-fullwidth no-header-border has-background-white has-text-dark table-fixed" v-if="!isLoading"> -->
      <table class="table w-full border-b-0 table-fixed bg-transparent">
        <thead>
          <Component_OKR_Header/>
        </thead>

      <tbody>
        <Component_OKR_Objective
          v-for="objective in objectives_member"
          v-bind:key="objective.id"
          v-bind:objective="objective"
          v-bind:keyresults="keyresults"
          v-bind:permissions="permissions"
          v-bind:activeWorkingPeriods="workingPeriods"
          v-bind:isPrior=false
          v-bind:isArchive=false
          v-bind:isPlanningActive="isPlanningActive"
          v-on:objectiveDeleted="handleObjectiveDeleted" 
          v-on:objectiveUpdated="handleObjectiveUpdated" 
          v-on:objectiveAdded="handleObjectiveAdded" 
        />
        <tr v-if="showNotMember" class="tableRow">
          <td class="okrFirstColumn verticalCenterTD"></td>
          <td class="okrSecondColumn verticalCenterTD"></td>
          <td>
            <div class="pb-3 pt-3 text-blue-400 font-bold text-left">Objectives anderer Teams:</div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

        </tr>
        <Component_OKR_Objective
          v-if="showNotMember"
          v-for="objective in objectives_not_member"
          v-bind:key="objective.id"
          v-bind:objective="objective"
          v-bind:keyresults="keyresults"
          v-bind:permissions="permissions"
          v-bind:activeWorkingPeriods="workingPeriods"
          v-bind:isPrior=false
          v-bind:isArchive=false
          v-bind:isPlanningActive="isPlanningActive"
          v-on:objectiveDeleted="handleObjectiveDeleted" 
          v-on:objectiveUpdated="handleObjectiveUpdated" 
          v-on:objectiveAdded="handleObjectiveAdded" 
        />

        <tr class="flex">
          <div v-if="permissions.includes('product.add_objective')" class="flex mt-[15px]">
            <!-- <button   @click="isAddObjectiveModalOpen = true" class="button has-background-grey-light round-button">+</button> -->
            <button @click="isAddObjectiveModalOpen = true" >
              <Component_Plus_Turn/>
            </button>
            <div class="vertical-center-flex text-gray-900 dark:text-gray-100">Objective hinzufügen</div>
          </div>

          <Teleport to="#modal">
            <Transition name="modal">
              <div v-if="isAddObjectiveModalOpen" class="modal-bg" @click.self="isAddObjectiveModalOpen = false">
                <div class="modal">
                  <Component_Modal_InfoButton :infoText="infotextAddObjective" />
                  <button @click="isAddObjectiveModalOpen = false" class="close-btn">x</button>
                  <Modal_AddObjective
                  v-if="permissions.includes('product.add_objective')"
                  v-on:objectiveAdded="handleObjectiveAdded" 
                  v-bind:activeWorkingPeriods="workingPeriods"
                  v-bind:isPlanningActive="isPlanningActive"
                  />                    
                </div>
              </div>
            </Transition>
          </Teleport>
        </tr>
      </tbody>
    </table>

    <div>
      <button v-if="permissions.includes('product.add_planning') && isPlanningActive === false" @click="isPlanningModalOpen = true" class="actionButton">Planning starten</button>
      <Teleport to="#modal">
        <Transition name="modal">
          <div v-if="isPlanningModalOpen" class="modal-bg" @click.self="isPlanningModalOpen = false">
            <div class="modal">
              <button @click="isPlanningModalOpen = false" class="close-btn">x</button>
              <Modal_AddPlanning
              v-if="permissions.includes('product.add_planning')"
              v-on:planningAdded="handlePlanningAdded" 
              v-on:workingPeriodsAdded="handleWorkingPeriodsAdded"
              />       
            </div>
          </div>
        </Transition>
      </Teleport>
      <Teleport to="#modal">
        <Transition name="modal">
          <div v-if="isChangePlanningModalOpen" class="modal-bg" @click.self="isChangePlanningModalOpen = false">
            <div class="modal">
              <button @click="isChangePlanningModalOpen = false" class="close-btn">x</button>
              <Modal_ChangePlanning
              v-if="permissions.includes('product.add_planning')"
              v-on:planningChanged="reloadCloseEverything" 
              v-bind:planning="planning"
              v-bind:objectives_inactive="objectives_inactive"
              />     
              <!-- v-bind:workingPeriods="workingPeriods" -->
            </div>
          </div>
        </Transition>
      </Teleport>
    </div>

    <div v-if="isPlanningActive">
      <div header class="column is-12">
        <h1>Auslaufende OKRs</h1>
      </div>
      

      <table class="table w-full border-b-0 table-fixed bg-transparent" v-if="!isLoading">
        <Component_OKR_Header>
        </Component_OKR_Header>
        <tbody>
          <Component_OKR_Objective
            v-for="objective in objectives_inactive"
            v-bind:key="objective.id"
            v-bind:objective="objective"
            v-bind:keyresults="keyresults"
            v-bind:permissions="permissions"
            v-bind:activeWorkingPeriods="workingPeriods"
            v-bind:isPrior=true
            v-bind:isArchive=false
            v-on:objectiveAdded="handleObjectiveAdded"
            v-on:objectiveDeleted="handleObjectiveDeleted"
            v-on:productAdded="handleObjectiveAdded" 
          />
          <!-- v-on:productDeleted="handleObjectiveDeleted" 
            v-on:productUpdated="handleObjectiveUpdated"  -->
        </tbody>
      </table>
    </div>



  </div>
</template>

<script>
import axios from 'axios'

import Component_OKR_Objective from '@/components/OKR/Component_OKR_Objective'
import Modal_AddObjective from '@/components/Modals/Modal_AddObjective'
import Modal_AddPlanning from '@/components/Modals/Modal_AddPlanning'
import Modal_ChangePlanning from '@/components/Modals/Modal_ChangePlanning'
// import Component_OKR_Objective_PriorPeriod from '@/components/OKR/Component_OKR_Objective_PriorPeriod'
import Component_OKR_Header from '@/components/Component_OKR_Header.vue'
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton'
import Component_Plus_Turn from '@/components/ButtonIcons/Component_Plus_Turn'

import { onClickOutside } from '@vueuse/core'
// import {ref} from 'vue'
// const isModalOpen = ref(false)
import { PlusIcon } from '@heroicons/vue/24/outline';
// import { getPermissions } from '@/utils/utils.js'

// import { get } from 'core-js/core/dict'



export default {
  name: 'HomeView',
  emits: ['requestPermissions'],
  components: {
    Component_OKR_Objective,
    Component_Plus_Turn,
    // Component_OKR_Objective_PriorPeriod,
    Component_OKR_Header,
    Component_Modal_InfoButton,
    Modal_AddObjective,
    Modal_AddPlanning,
    Modal_ChangePlanning,
    PlusIcon,
  },
  data() {
    return {
      objectives_active: [],
      objectives_inactive: [],
      objectives_member: [],
      objectives_not_member: [],
      currentUserId: '',
      teams: [],
      keyresults: [],
      // permissions: [],
      planning: '',
      // activePlanning: '',
      workingPeriods: [],
      // activeWorkingPeriods: [],
      // isPrior: false,
      isPlanningActive: false,
      isAddObjectiveModalOpen: false,
      isPlanningModalOpen: false,
      isChangePlanningModalOpen: false,
      isLoading: true,
      infotextAddObjective: 'Objectives werden rein Qualitativ definiert.',
      showNotMember: false,
    }
  },
  computed: {
    permissions() {
        return this.$store.state.permissions;
    },
  },
  mounted() {   
    document.title = 'Home | OKRs';
    this.$emit('requestPermissions');

    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.$store.commit('setIsLoading', true);
      // this.getIaaaaD();

      await Promise.all([
        this.getUserIDAndObjectivesAndTeams(),
        this.getKeyresults(),
        this.getPlanning(),
        this.getWorkingPeriods(),
        this.$store.dispatch('fetchPermissions'),
      ]);
      console.log('working periods:', this.workingPeriods);
      // await this.getObjectives();
      // await this.getTeams(),
      // await this.filterObjectivesMember();

      // this.filterActivePlanning();
      // if (this.planning) {
        // console.log('active planning:', this.activePlanning);
        // this.findWorkingPeriods();
        // this.filterWorkingPeriods();
        // console.log('working periods:', this.workingPeriods);
        // console.log('keyresults:', this.keyresults);
      // }

      this.isLoading = false;
      this.$store.commit('setIsLoading', false);
    },

    async getUserIDAndObjectivesAndTeams() {
      await this.getUserID();
      await this.getObjectives();
      // console.log('objectives_active:', this.objectives_active);
      await this.getTeams();
      // console.log('teams:', this.teams);
      await this.filterObjectivesMember();
      // console.log('objectives Member:', this.objectives_member);
    },
    async getUserID() {
      try {
        const response = await axios.get('/api/v1/me/');
        this.currentUserId = response.data.id;
        // console.log('current user id:', this.currentUserId);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async getObjectives() {
      this.$store.commit('setIsLoading', true);
      try {
        // console.log('get objectives');  
        const response = await axios.get('/api/v1/objectives/');
        this.objectives_inactive = response.data.filter(objective => !objective.is_active);
        this.objectives_active = response.data.filter(objective => objective.is_active);
        // console.log('objectives:', response.data);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
      this.$store.commit('setIsLoading', false);
    },
    async getTeams() {
      try {
        const response = await axios.get('/api/v1/teams/');
        this.teams = response.data;
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    },
    async filterObjectivesMember() {
      // console.log('current user id:', this.currentUserId);
      if (this.teams.length > 0) {
        const filteredTeams = this.teams.filter(team => team.members.includes(this.currentUserId));
        // console.log('filtered teams:', filteredTeams);
        const filteredTeamIds = filteredTeams.map(team => team.id);
        // console.log('filtered team ids:', filteredTeamIds);
        this.objectives_member = this.objectives_active.filter(objective => filteredTeamIds.includes(objective.team));
        // console.log('objectives member:', this.objectives_member);
        this.objectives_not_member = this.objectives_active.filter(objective => !filteredTeamIds.includes(objective.team));
        // console.log('objectives not member:', this.objectives_not_member);
      } else {
        this.objectives_not_member = this.objectives_active;
      }
    },
    async getKeyresults() {
      this.$store.commit('setIsLoading', true);
      try {
        const response = await axios.get('/api/v1/keyresults/');
        this.keyresults = response.data;
      } catch (error) {
        console.error('Error fetching keyresults:', error);
      }
      this.$store.commit('setIsLoading', false);
    },
    async getPlanning() {
      this.$store.commit('setIsLoading', true);
      try {
        const response = await axios.get('/api/v1/plannings/');
        this.planning = response.data;
        if (this.planning.is_active === true) {
          this.isPlanningActive = true;
        }
        else {
          this.isPlanningActive = false;
        }
        // console.log('planning after getting:', this.planning);
      } catch (error) {
        console.error('Error fetching plannings:', error);
      }
      this.$store.commit('setIsLoading', false);
    },
    // findWorkingPeriods() {
    //   this.workingPeriods = [];
    //   if (Array.isArray(this.planning.working_periods)) {
    //     this.planning.working_periods.forEach(working_periods => {
    //       this.workingPeriods.push(working_periods);
    //     });
    //   } else {
    //     this.workingPeriods.push(this.planning.working_periods);
    //   }
    // },
    async getWorkingPeriods() {
      try {
        const response = await axios.get('/api/v1/working-periods/');
        this.workingPeriods = response.data;
        console.log('working periods:', this.workingPeriods);
      }
      catch (error) {
        console.error('Error fetching working periods:', error);
      }
    },
    // filterWorkingPeriods() {
    //   this.activeWorkingPeriods = this.workingPeriods.filter(workingPeriod => workingPeriod.is_active === true);
    //   console.log('active working periods:', this.activeWorkingPeriods);
    // },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // This will return the date in YYYY-MM-DD format
    },
    async reloadCloseEverything() {
      this.isAddObjectiveModalOpen = false;
      this.isPlanningModalOpen = false;
      this.isChangePlanningModalOpen = false;
      await this.loadData();


    },
    async handleObjectiveDeleted() {
      // Fetch products again after a product is deleted
      await this.getObjectives();
    },
    async handleObjectiveUpdated() {
      // Fetch products again after a product is deleted
      await this.getObjectives();
    },
    async handleObjectiveAdded() {
      this.loadData();
      this.isAddObjectiveModalOpen = false;
      this.$emit('requestPermissions');

    },
    async handleObjectiveDeleted() {
      this.loadData();
    },
    async handlePlanningAdded() {
      this.loadData();
      this.isPlanningModalOpen = false;
    },
    async handleWorkingPeriodsAdded() {
      this.loadData();
      // this.isPlanningModalOpen = false;
    },
  }
}
</script>

<style>
.no-header-border thead th {
  border-bottom: none;
}

.narrow-column {
  width: 100px; /* Adjust width as needed */
  /* background-color: black;  */
}


</style>

