<template>
    <div v-if="!isLoading" class="main-wrapper w-full text-center items-center justify-center content-center">
        <h1>Lizenzverwaltung</h1>
        <div class="table w-full table-fixed bg-transparent "></div>


        <table class="table w-full max-w-4xl mx-auto no-header-border table-fixed bg-transparent">
            <thead>
                <tr>
                    <td class="font-bold w-[200%] text-left"><span>Lizenztyp</span></td>              
                    <td class="font-bold w-[100%] text-center"><span>Gesamt</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Frei</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Vergeben</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Aktiviert</span></td>
                </tr>
            </thead>
            
            <tbody>
                <!-- TODO make this dependent on permissions or user status -->
                <tr class="tableRow" v-if="this.licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div>Administrator:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow" v-if="licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div>Teamlead:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow">
                    <td class="verticalCenterTD text-left">
                        <div>Standard:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow" v-if="this.licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div class="ml-6">Davon an Teamleads übertragen:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.activated.length }}</div>
                    </td>
                </tr>
                <tr>
                    <div class="flex flex-wrap w-full items-center">
                        <button @click="isAssignLicensesModalOpen = true" class="actionButton">Lizenzen vergeben</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isAssignLicensesModalOpen" class="modal-bg" @click.self="isAssignLicensesModalOpen = false">
                                    <div class="modal">
                                        <button @click="isAssignLicensesModalOpen = false" class="close-btn">x</button>
                                        <Modal_Company_AssignLicenses
                                        v-bind:licenses="licenses"
                                        v-on:licenseAssigned="handleEmit"
                                        />
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </div>  
                </tr>
            </tbody>
        </table>
        
        <h1>User</h1>
        <table class="table w-full max-w-6xl mx-auto no-header-border table-fixed bg-transparent">
            <thead>
                <tr>
                    <td class="font-bold w-[200%] text-left"><span>E-Mail</span></td>              
                    <td class="font-bold w-[100%] text-center"><span>Vorname</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Nachname</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Nutzer seit</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Lizenztyp</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Account Aktiviert</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Verw. Teamlead Lizenzen</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Verw. Standard Lizenzen</span></td>
                </tr>
            </thead>
            
            <tbody>
                <Component_Licenses_User
                v-for="user in users"
                v-bind:user="user"
                v-bind:licenses_teamlead="licenses_teamlead(user)"
                v-bind:licenses_standard="licenses_standard(user)"
                v-bind:licensetype_user="licensetype_user(user)"
                />  

            </tbody>

        </table>
    </div>
</template>

<script>
import Modal_Company_AssignLicenses from '@/components/Modal_Company_AssignLicenses.vue';
import Component_Licenses_User from '@/components/Licenses/Component_Licenses_User.vue';
import { UsersIcon } from '@heroicons/vue/24/outline';
import axios from 'axios';

export default {
    name: 'MyAccount',
    components: {
        Modal_Company_AssignLicenses,
        Component_Licenses_User,
        // Modal_Company_LicenseDetails,
    },
    props: {
        me: {
            type: Object,
            required: true,
        },
    },
    computed: {
        permissions() {
            return this.$store.state.permissions;
        },
    },
    data() {
        return {
            users: [],
            licenses: {
                admin: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                teamlead: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                standard: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                standard_redistributed: {
                all: [],
                used: [],
                free: [],
                activated: []
                }
            },
            isAssignLicensesModalOpen: false,
        }
    },
    mounted() {
        this.getLicenses();
        this.getUsers();
    },
    methods: {
        async getLicenses() {
            // console.log('getLicenses');
            try {
                const response = await axios.get('/api/v1/company-licenses/');
                // console.log(response.data);

                Object.keys(this.licenses).forEach(category => {
                    this.licenses[category].all = [];
                    this.licenses[category].used = [];
                    this.licenses[category].free = [];
                    this.licenses[category].activated = [];
                });

                // TODO can be made much more simple
                // Categorize licenses in a single loop)
                response.data.forEach(license => {
                    console.log('licenses:', license)
                    console.log('redistributor:', license.redistributor)
                    console.log('me:', this.me)

                    if (license.is_company_admin) {
                        this.licenses.admin.all.push(license);
                        if (license.user) {
                            this.licenses.admin.used.push(license);
                            if (license.is_activated) {
                                this.licenses.admin.activated.push(license);
                            }
                        } else {
                            this.licenses.admin.free.push(license);
                        }
                    } else if (license.is_company_teamlead) {
                        this.licenses.teamlead.all.push(license);
                        if (license.user) {
                            this.licenses.teamlead.used.push(license);
                            if (license.is_activated) {
                                this.licenses.teamlead.activated.push(license);
                            }
                        } else {
                            this.licenses.teamlead.free.push(license);
                        }
                    } else {
                        this.licenses.standard.all.push(license);
                        if (license.user) {
                            this.licenses.standard.used.push(license);
                            if (license.is_activated) {
                                this.licenses.standard.activated.push(license);
                            }
                            // TODO Luka here me.id is not the correct ID. THis whole thing should be overworked anyways
                        } else if (license.redistributor && license.redistributor != this.me.id) {
                            this.licenses.standard_redistributed.all.push(license);
                            if (license.user) {
                                this.licenses.standard_redistributed.used.push(license);
                                if (license.is_activated) {
                                    this.licenses.standard_redistributed.activated.push(license);
                                }
                            } else {
                            this.licenses.standard_redistributed.free.push(license);
                            }
                        } else {
                            this.licenses.standard.free.push(license);
                        }
                    }
                });
                console.log('Licenses:', this.licenses);
            } catch(error) {
                console.log(error);
            };
        },
        async getUsers() {
            const token = localStorage.getItem('token');
            console.log('Token:', token);
            try {
                const response = await axios.get(`/api/v1/company-users`, {
                headers: {
                    'Authorization': 'Token ' + token,
                }
                });
                console.log('response getUsers:', response);
                this.users = response.data;
                console.log("this.users:", this.users)
            } catch (error) {
                console.error('Error getting users:', error);
                console.error('Error getting users:', error.response.data);
            }
        },
        licenses_teamlead(user) {
            console.log('licenses:', this.licenses, 'user:', user)
            return this.licenses.teamlead.all.filter(license => license.redistributor === user.id).length;
        },
        licenses_standard(user) {
            return this.licenses.standard.all.filter(license => license.redistributor === user.id).length;
        },
        licensetype_user(user) {
            if (this.licenses.admin.all.filter(license => license.user === user.id).length > 0) {
                return 'Administrator';
            } else if (this.licenses.teamlead.all.filter(license => license.user === user.id).length > 0) {
                return 'Teamlead';
            } else if (this.licenses.standard.all.filter(license => license.user === user.id).length > 0) {
                return 'Standard';
            } else {
                return 'Keine Lizenz';
            }
        },
        handleEmit() {
            console.log("handleEmit Licenses")
            this.getLicenses();
            this.$store.dispatch('fetchPermissions');
            this.isAssignLicensesModalOpen = false;
        }
    }
}


</script>