import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/logger'; // Import the logger utility to override console methods

import axios from 'axios'
// import axios from '@/services/axiosConfig'

import { createVuetify } from 'vuetify'; // Import Vuetify
import 'vuetify/dist/vuetify.min.css';
import './assets/tailwind.css'


import './styles_global/styles_global.css' // Here global styles imported for all views and components


// axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://api.pivot-tree.de/'


// Create your Vue app instance
const app = createApp(App);

// Initialize Vuetify
const vuetify = createVuetify();

// app.config.globalProperties.$axios = axios;


// Use plugins (router, store, and axios)
app.use(router, axios);
app.use(store);

// Use Vuetify in your app
app.use(vuetify);

// Mount your app to the #app element
app.mount('#app');

// In the beginning it was like this
// createApp(App).use(store).use(router, axios).mount('#app')
