<template>
  <div v-if="!isError" class="main-content">
    <div  header class="w-full text-3xl text-center pb-3">Bitte wählen Sie Ihr Passwort</div>
    <form @submit.prevent="submitForm">
      <div class="field">
          <label class="text-left">Neues Passwort:</label>
          <div class="control">
            <input type="password" class="input-field-big" v-model="password" id="password" required>
          </div>
      </div>
      <div class="field">
          <label class="text-left">Passwort bestätigen:</label>
          <div class="control">
            <input type="password" class="input-field-big" v-model="passwordConfirm" id="passwordConfirm" required>
          </div>
      </div>
      <div class="field">
          <div class="control horizontal-center">
            <button class="button is-dark">Passwort setzen</button>
          </div>
      </div>
    </form>
  </div>
    <!-- <div v-if="message" :class="{'error': isError, 'success': !isError}">
      {{ message }}
    </div> -->
  <div v-else>
    <div class="main-content">
      <div header class="w-full text-3xl text-center pb-3">
        Der Link ist nicht Berechtigt für diese Aktion.<br> 
        Bitte versuchen Sie es erneut. </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      password: '',
      passwordConfirm: '',
      message: '',
      isError: false,
    };
  },
  computed: {
    permissions() {
        return this.$store.state.permissions;
    },
  },
  async created() {
    const uidb64 = this.$route.params.uidb64;
    const token = this.$route.params.token;
    try {
      const response = await axios.get(`/api/v1/reset/${uidb64}/${token}/`);
      this.message = response.data.message;
      this.isError = false;
    } catch (error) {
      this.message = error.response.data.error || 'An error occurred';
      this.isError = true;
    }
  },
  methods: {
    async submitForm() {
      const uidb64 = this.$route.params.uidb64;
      const token = this.$route.params.token;
      try {
        const response = await axios.post(`/api/v1/reset/${uidb64}/${token}/`, {
          new_password1: this.password,
          new_password2: this.passwordConfirm,
        });
        this.message = response.data.message;
        this.isError = false;
        this.$router.push('/log-in-to-okr');

        
      } catch (error) {
        this.message = error.response.data.error || 'An error occurred';
        this.isError = true;
      }
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.success {
  color: green;
}
</style>