<template>
    <div>
        <!-- <div id="wrapper">
            <nav class="navbar-top-left">
                <div class="navbar-top-menu">
                    <div class="navbar-top-item">
                        <router-link to="/front-page" class="button-navbar-top">vanta OKR</router-link>
                    </div>
                </div>
            </nav>
        </div>   -->

        <div class="main-content">
            <h1>Login</h1>

            <form @submit.prevent="submitForm">
                <!-- TODO add csrf tokens in every form -->
                <div class="field">
                    <div class="text-left">E-Mail</div>
                    <div class="control">
                        <input type="email" class="input w-[250px]" v-model="email">
                    </div>
                </div>

                <div class="field">
                    <div class="text-left">Passwort</div>
                    <div class="control">
                        <input type="password" class="input w-[250px]" v-model="password">
                    </div>
                </div>

                <div class="field">
                    <div class="control horizontal-center">
                        <button class="button is-dark">Log in</button>
                    </div>
                </div>
                <!-- <div class="horizontal-center">
                    <hr>Zum Registrieren <RouterLink class="m-[5px]" to="/sign-up-to-okr">hier klicken</RouterLink>
                </div> -->
            </form>
            <div class="notification is-danger mt-4" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LogIn',
    emits: ['requestPermissions'],
    data() {
        return {
            email: '',
            password: '',
            errors: []
        }
    },
    // watch: {
    //     email(newEmail) {
    //         this.email = newEmail.toLowerCase();
    //     }
    // },
    mounted() {
        document.title = 'Log In | VANTA OKR' 
    },
    methods: {
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")

            const formData = {
                email : this.email.toLowerCase(),
                password: this.password
            }
            // console.log('formData:', formData)

            await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token "+ token
                    axios.defaults.withCredentials = true
                    localStorage.setItem("token", token)
                    
                    this.$store.dispatch('fetchPermissions')

                    // print('user:', user)
                    // this.$store.commit('SET_CURRENT_USER', user);

                    // const toPath = this.$route.query.to || '/cart'
                    
                    // this.$emit('requestPermissions');
                    this.$nextTick(() => {
                        this.$router.push('/okrs')
                    });

                    // this.$router.push('/blank')
                    // window.location.href = '/okrs';
                })
                .catch(error => {
                    if (error.response) {
                        console.error('Error response:', error.response.data);

                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                    } else if (error.request) {
                        console.error('Error request:', error.request);
                    } else {
                        console.error('Error message:', error.message);

                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>

